<template>
  <div>
    <passContainer
      :sections="sections"
      :activeSection="activeSection"
      @moveForm="moveForm($event)"
    ></passContainer>
    <titleForm
      title="Creando una nueva"
      strong="Actividad del Plan de Mejora"
    ></titleForm>
    <form
      id="planForm"
      class="mainForm mb-3 needs-validation"
      v-bind:class="{ 'was-validated': showValidate }"
    >
      <div class="xScroll d-flex">
        <first
          :branchID="branchID"
          :defaultData="{
            registrant: summary.registrant,
            responsable: summary.responsable
          }"
          :submitValidate="submitStart"
          :sectionName="sections[0]"
          sectionPosition="0"
          @moveForm="moveForm($event)"
        ></first>
        <second
          :branchID="branchID"
          :defaultData="{
            suggested: summary.suggested,
            origin: summary.origin,
            target: summary.target,
            standard: summary.standard
          }"
          :submitValidate="submitStart"
          :sectionName="sections[1]"
          sectionPosition="1"
          @moveForm="moveForm($event)"
        ></second>
        <third
          :defaultData="{
            description: summary.description,
            activity: summary.activity,
            limitDate: summary.limitDate
          }"
          :submitValidate="submitStart"
          :sectionName="sections[2]"
          sectionPosition="2"
          @moveForm="moveForm($event)"
        ></third>
        <fourth
          :defaultData="{
            humanResource: summary.humanResource,
            technicalResource: summary.technicalResource,
            financialResource: summary.financialResource
          }"
          :submitValidate="submitStart"
          :sectionName="sections[3]"
          sectionPosition="3"
          @moveForm="moveForm($event)"
        ></fourth>
        <fifth
          :defaultData="{
            support: summary.support
          }"
          :submitValidate="submitStart"
          :sectionName="sections[4]"
          sectionPosition="4"
          submitName="Editar Actividad"
          @moveForm="moveForm($event)"
          @submitForm="submit()"
        ></fifth>
      </div>
    </form>
    <Legal></Legal>
  </div>
</template>

<script>
import Storage from "@/modules/storage";
import { validateFormData } from "@/modules/tools";
import Swal from "sweetalert2";

import passContainer from "@/components/form/passContainer";
import titleForm from "@/components/form/Title";
import Legal from "@/components/layout/Legal";

import first from "@/views/plain/form/summary/1";
import second from "@/views/plain/form/summary/2";
import third from "@/views/plain/form/summary/3";
import fourth from "@/views/plain/form/summary/4";
import fifth from "@/views/plain/form/summary/5";

export default {
  data() {
    return {
      companyId: "",
      branchID: "",
      sections: [
        "Empleados",
        "Variables",
        "Hallazgo y Actividad",
        "Recursos",
        "Soporte"
      ],
      activeSection: "",
      submitStart: false,
      showValidate: false,
      summary: ""
    };
  },
  created() {
    const store = new Storage();
    store.getData("summary", this.$route.params._id).then(summary => {
      this.summary = summary;
      store.getData("branch", summary.branch).then(branch => {
        this.branchID = branch._id;
      });
    });
    store.getData("company").then(data => {
      this.companyId = data[0]._id;
    });
    this.activeSection = this.sections[0];
  },
  methods: {
    submit() {
      const planForm = document.getElementById("planForm");
      const select = planForm.getElementsByTagName("select");
      const textarea = planForm.getElementsByTagName("textarea");
      const input = planForm.getElementsByTagName("input");
      let submit = true;

      this.submitStart = !this.submitStart;
      this.showValidate = true;

      for (let i = 0; i < select.length; i++) {
        if (!validateFormData(select[i])) {
          submit = false;
        }
      }
      for (let i = 0; i < textarea.length; i++) {
        if (!validateFormData(textarea[i])) {
          submit = false;
        }
      }
      for (let i = 0; i < input.length; i++) {
        if (!validateFormData(input[i])) {
          submit = false;
        }
      }

      if (submit) {
        this.sendData(planForm);
      }
    },
    sendData(form) {
      const store = new Storage();
      const formData = new FormData(form);
      let phone;

      Swal.fire({
        icon: "warning",
        title: "Editando actividad",
        html:
          "Por favor espere mientras se edita la actividad</b> " +
          '<div class="progress mt-3"><div class="progress-bar" id="progressBranch" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true
      });

      let limitDate = formData.get("limitDate");
      const year = limitDate.split("-")[0];
      let month = limitDate.split("-")[1] - 1;
      if (month < 10) {
        month = "0" + month.toString();
      }
      let day = limitDate.split("-")[2];
      day++;
      if (day < 10) {
        day = "0" + day.toString();
      }
      limitDate = new Date(Date.UTC(year, month, day));
      limitDate = limitDate.getTime();

      let registrant = formData.get("registrant");
      if (registrant === "") {
        registrant = null;
      }

      const data = {
        _id: this.$route.params._id,
        registrant: registrant,
        responsable: formData.get("responsable"),
        suggested: formData.get("suggested"),
        origin: formData.get("origin"),
        target: formData.get("target"),
        standard: formData.get("standard"),
        description: formData.get("description"),
        activity: formData.get("activity"),
        humanResource: formData.get("humanResource"),
        technicalResource: formData.get("technicalResource"),
        financialResource: formData.get("financialResource"),
        limitDate: limitDate
      };

      store.updateData("summary", [data], true, "optima").then(data => {
        store.getData("file").then(fileList => {
          const fileInput = formData.get("fileInput");

          if (fileInput.name !== "") {
            let parent;
            fileList.forEach(file => {
              if (
                file.name === "Plan de Trabajo y Mejoramiento" &&
                file.branch === this.summary.branch
              ) {
                parent = file._id;
              }
            });

            const fileData = {
              expiration: null,
              responsable: null,
              createdBy: localStorage.getItem("user._id"),
              branch: this.summary.branch,
              extension: formData.get("extension"),
              name: formData.get("name"),
              parent: parent,
              size: parseInt(formData.get("fileSize"))
            };
            store
              .insertData("optima", "file", [fileData])
              .then(data => {
                const progressBranch = document.getElementById(
                  "progressBranch"
                );
                const percentUpdate = (loaded, total, percent) => {
                  percent -= 5;
                  progressBranch.innerText = percent.toString() + "%";
                  progressBranch.style.width = percent.toString() + "%";
                };

                formData.append("_id", data[0]._id);
                formData.append("parent", parent);
                store
                  .uploadFile(
                    "file",
                    data[0]._id,
                    "optima/file/upload",
                    formData,
                    percentUpdate
                  )
                  .then(res => {
                    store
                      .getData("summary", this.$route.params._id)
                      .then(summary => {
                        summary.support = data[0]._id;
                        delete summary.registrationDate;
                        delete summary.lastUpdate;
                        store
                          .updateData("summary", [summary], true, "optima")
                          .then(() => {
                            this.endSubmit(true);
                          });
                      });
                  })
                  .catch(error => {
                    console.log(error);
                    this.endSubmit(false);
                  });
              })
              .catch(error => {
                console.log(error);
                this.endSubmit(false);
              });
          } else {
            this.endSubmit(true);
          }
        });
      });
    },
    endSubmit(status) {
      if (status) {
        Swal.fire({
          icon: "success",
          title: "Actividad editada"
        }).then(result => {
          this.$router.go(-1);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error al editar la actividad",
          text:
            "Los datos han sido guardados en su computador o celular, una vez se restablesca la conexión, los datos se sincronizarán con el servidor"
        }).then(result => {
          this.$router.go(-1);
        });
      }
    },
    moveForm(event) {
      const xScroll = document.getElementsByClassName("xScroll")[0];
      let postion;

      if (event === "forward") {
        for (let i = 0; i < this.sections.length; i++) {
          if (this.activeSection === this.sections[i]) {
            postion = i + 1;
            break;
          }
        }
      } else if (event === "back") {
        for (let i = 0; i < this.sections.length; i++) {
          if (this.activeSection === this.sections[i]) {
            postion = i - 1;
            break;
          }
        }
      } else {
        postion = event;
      }
      this.activeSection = this.sections[postion];
      xScroll.style.marginLeft = postion * 100 * -1 + "%";
    }
  },
  components: {
    passContainer,
    titleForm,
    Legal,
    first,
    second,
    third,
    fourth,
    fifth
  },
  name: "Summary Create"
};
</script>

<style lang="css" scoped>
.xScroll {
  margin-left: 0%;
  transition: 0.3s ease-in-out;
}
.mainForm {
  z-index: 8;
  position: absolute;
  width: 100vw;
  overflow-x: hidden;
}
</style>
